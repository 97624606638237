<template>
  <v-row>
    <v-col cols="12">
      <v-data-table :headers="headers" :items="items" dense hide-default-footer disable-pagination>
        <template v-slot:item.no="{ index }">
          {{ index + 1 }}
        </template>
        <template v-slot:item.detail="{ item }">
          <v-list-item v-for="(data, index) in item.items" :key="index">
            <v-list-item-content>
              <v-list-item-title>
                <v-row>
                  <v-col cols="12" sm="12" md="6" lg="4">
                    <v-select
                      dense
                      outlined
                      flat
                      background-color="white"
                      :items="listType"
                      v-model="data.type"
                      disabled
                      label="Type"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="12" md="6" lg="4">
                    <v-autocomplete
                      outlined
                      flat
                      dense
                      :items="employees"
                      item-text="name"
                      item-value="hrId"
                      background-color="white"
                      v-model="data.employeeId"
                      label="Assign To"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="12" md="6" lg="4">
                    <v-menu
                      v-model="data.menuDate"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          outlined
                          flat
                          dense
                          background-color="white"
                          v-model="data.date"
                          append-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          label="Date"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="data.date"
                        @input="data.menuDate = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
        <template v-slot:no-data>
          <v-btn color="primary" @click="getTemplates">
            Reset
          </v-btn>
        </template>
      </v-data-table>
    </v-col>
    <v-col cols="12" class="text-right">
      <v-btn color="primary" @click="save" class="mr-2">save</v-btn>
      <v-btn color="error" @click="close">cancel</v-btn>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "create-check-balance",
  data() {
    return {
      items: [],
      listType: ["User", "Checker", "Approved"],
      headers: [
        {
          text: "No",
          align: "center",
          sortable: false,
          value: "no",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          text: "Month",
          value: "month",
          sortable: false,
          divider: true,
          align: "center",
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          text: "Name",
          value: "name",
          sortable: false,
          divider: true,
          align: "center",
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          text: "Remark",
          value: "remark",
          sortable: false,
          divider: true,
          align: "center",
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          text: "Detail",
          value: "detail",
          sortable: false,
          divider: true,
          align: "center",
          class: "light-blue lighten-2 font-weight-black",
        },
      ],
    };
  },
  computed: {
    ...mapState("external", ["employees"]),
  },
  methods: {
    async save() {
      this.$store.commit("SET_LOADER", true);
      this.$store
        .dispatch("checkBalance/saveCheckBalance", this.items)
        .then(() => {
          this.$store.commit("SET_LOADER", false);
        })
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
    close() {
      this.$router.go(-1);
    },
    async getTemplates() {
      this.$store.dispatch("checkBalance/getTemplateForCheckBalance").then(response => {
        this.items = response.data;
      });
    },
    async getInitialData() {
      this.$store.commit("SET_LOADER", true);
      Promise.all([await this.$store.dispatch("external/getEmployees"), this.getTemplates()])
        .then(() => this.$store.commit("SET_LOADER", false))
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
  },
  mounted() {
    this.getInitialData();
  },
};
</script>

<style></style>
